@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

.bg-gradient-2 {
    background-image: linear-gradient(#eafdfc, #adcce9);
}

.animate__animated.animate__slideInRight {
    --animate-delay: 2s;
    --animate-duration: 1.75s;
}

.animate__animated.animate__fadeIn {
    --animate-delay: 5s;
    --animate-duration: 2.25s;
}